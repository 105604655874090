* {
	margin: 0;
	padding: 0;
}
body {
	background-color: #371514;
	color: white;
}
table,
th,
td,
tbody,
thead {
	color: white;
}
table,
tr,
td {
	border: 2px solid #ccc;
}
